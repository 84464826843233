<template>
  <div id="exhibition-2" class="exhibition-container">
    <btnHome />
    <div class="right-toolbar">
      <div class="subtitle">
        <img
          src="@/assets/images/subtitle-2.svg"
          width="100%"
          height="auto"
          alt=""
        />
      </div>
      <div class="section-btn-container">
        <div
          v-for="(item, i) in sectionsName"
          :key="`section-${i}`"
          :class="{ 'section-btn': true, active: currentSection == i }"
          @click="clickSection(i)"
        >
          <div class="btn-color"></div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <router-view />
    </transition>
  </div>
</template>

<script>
import { Howl, Howler } from "howler";
import btnHome from "@/components/btnHome";

// import { gsap, Power2 } from "gsap";
export default {
  data() {
    return {
      myBase: null,
      volume: 0,
      sectionsName: ["instruments", "compare", "iconography"],
    };
  },
  components: { btnHome },
  mounted() {
    this.$store.commit("updateVisited", 1);
    document.querySelector("html,body").style.overflow = "auto";
  },
  methods: {
    clickSection(i) {
      if (i == this.currentSection) return;
      this.$store.commit("updateCurrentSection", i);
      this.$router.push({ name: this.sectionsName[i] });
    },
  },
  computed: {
    currentSection() {
      return this.$store.state.gallery2.currentSection;
    },
    soundMain() {
      return this.$store.state.sound.gallery2.main;
    },
  },
};
</script>

<style lang="scss">
#exhibition-2 {
  width: 100%;
  height: 100%;
  background: #000;
}
</style>
